
.page {
  display:          flex;
  flex-direction:   column;

  justify-content:  center;
  text-align:       center;
}

.underlay {
  position:       fixed;
  top:            0;
  left:           0;
  z-index:        1;
}

.content {
  position: absolute;
  top: 0;
  right: auto;
  left: auto;
  width: 100%;

  overflow-y: auto; /* Enables scrolling for the content */
  z-index: 2;
}

.small {
  font-size: 0.7em;
}


#LandingScreen ul {
    list-style: none;
    margin-top: 1em;
}


.SocialMediaLinks {
    display:          flex;
    flex-direction:   row;
    justify-content:  center;
    align-items:      center;
    text-align:       center;
    font-size:        2.2rem;
    gap:              1.5rem;
    margin-bottom:  0.2em;
}

.SocialMediaLinks a {
    display:          inline-flex;

    transition:       transform 0.3s, color 0.3s;
    justify-content:  center;
    align-items:      center;
    color:            grey;
}

.SocialMediaLinks a:hover {
    color:       white;
    transform:   scale(1.1);
}

.SocialMediaLinks svg {
    width:        100%;
    height:       100%;
    fill:         currentColor;
}


/* Styles for mobile users */
@media only screen and (max-width: 768px) {

  .portfolio {
    margin-top:     2em;
    margin-bottom:  1em;
    text-align:     center;
  }

  .textLinks {
      display:      block;
  }

}


/* Styles for desktop users */
@media only screen and (min-width: 769px) {

.portfolio {
  margin-top:     2em;
  margin-bottom:  2em;
  text-align:     center; /* Centers the heading */
}

.textLinks {
    display:          none;
    list-style-type:  none;
    padding:          0;
    margin:           0;
    text-align:       center;
}

.textLinks li {
    margin: 1em 0;
}

}

