.thumbnailCarousel {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
}

.carouselContainer {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}

.thumbnail {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail img {
  max-width:  100%;
  max-height: 300px;
  object-fit: cover;
}

.indicators {
  display:          flex;
  justify-content:  center;
  margin-top:       1em;
}

.indicators span {
  width:            10px;
  height:           10px;
  background-color: gray;
  border-radius:    50%;
  margin:           0 5px;
  cursor:           pointer;
  transition:       background-color 0.3s ease;
}

.textLinks {
  display: none;
}

.indicators .active {
  background-color: black;
}


@media (max-width: 767px) {
  .carouselContainer,
  .indicators {
    display: none;
  }

  .textLinks {
    display: block;
  }
}
