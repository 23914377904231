body {
    margin:                   0;
    padding:                  0;
    color:                    #ffffff;
    background-color:         #002;
    -webkit-font-smoothing:   antialiased;
    -moz-osx-font-smoothing:  grayscale;
}

body
{
  font-family: 'Roboto Mono', sans-serif;
}

h1, h2{
  font-family: 'Russo One', Arial, sans-serif;
  margin: 0.1em 0 0.1em 0;
  padding: 0;
}

p {
  color:    #cccccc;
  margin:   0;
  padding:  0;
}

a {
  color: #fff;
}
